import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)
Vue.use(VueRouter)
function importViewComponent(path) {
  return () => import(`../views/${path}.vue`)
}
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: importViewComponent('HomePage'),
    },
    {
      path: '/login',
      name: 'login',
      component: importViewComponent('Login'),
    },
    {
      path: '/register',
      name: 'register',
      component: importViewComponent('RegisterPage'),
    },
    {
      path: '/daftar',
      name: 'daftarumat',
      component: importViewComponent('RegisterUmatPage'),
    },
    {
      path: '/detailpengumuman/:id',
      name: 'detailpengumuman',
      component: importViewComponent('DetailPengumumanPage'),
    },
    {
      path: '/buatjanjiromo/:id',
      name: 'buatjanjiromo',
      component: importViewComponent('BuatJanjiRomoPage'),
    },
    //daftar sakramen
    {
      path: '/daftarsakramen/baptis-anak',
      name: 'daftarsakramenbaptisanak',
      component: importViewComponent('DaftarSakramenBaptisAnak'),
    },
    {
      path: '/daftarsakramen/baptis-dewasa',
      name: 'daftarsakramenbaptisanak',
      component: importViewComponent('DaftarSakramenBaptisDewasa'),
    },
    {
      path: '/daftarsakramen/perkawinan',
      name: 'daftarsakramenperkawinan',
      component: importViewComponent('DaftarSakramenPerkawinan'),
    },
    {
      path: '/daftarsakramen/komuni-pertama',
      name: 'daftarsakramenkomunipertama',
      component: importViewComponent('DaftarSakramenKomuniPertama'),
    },
    {
      path: '/daftarsakramen/krisma',
      name: 'daftarsakramenkrisma',
      component: importViewComponent('DaftarSakramenKrisma'),
    },
    //daftar pelayanan umum
    {
      path: '/daftarpelayanan/keterangan-umum',
      name: 'daftarpelayananumum',
      component: importViewComponent('DaftarPelayananUmum'),
    },
    {
      path: '/daftarpelayanan/pindah-domisili',
      name: 'daftarpelayananpindahdomisili',
      component: importViewComponent('DaftarPelayananPindahDomisili'),
    },
    {
      path: '/daftarpelayanan/kematian',
      name: 'daftarpelayanankematian',
      component: importViewComponent('DaftarPelayananKematian'),
    },
    {
      path: '/daftarpelayanan/pelayan-ekaristi',
      name: 'daftarpelayananekaristi',
      component: importViewComponent('DaftarPelayananEkaristi'),
    },
    {
      path: '/',
        component: importViewComponent('DashboardLayout'),
        children: [
            {
              path: '/editprofile/:id',
              name: 'EditProfile',
              meta: {title: 'Edit profile'},
              component: importViewComponent('EditProfilePage'),
            },
            //edit profile umat 
            {
              path: '/updateprofile/:id',
              name: 'EditProfileUmat',
              meta: {title: 'Edit profile'},
              component: importViewComponent('EditProfileUmatPage'),
            },
            //Dashboard petugas
            {
                path: '/dashboardpage',
                name: 'DashboardPage',
                meta: {title : 'Dashboard'},
                component: importViewComponent('DashboardPage'),
            },
            //Dashboard umat
            {
              path: '/dashboard',
              name: 'Dashboard',
              meta: {title : 'Dashboard'},
              component: importViewComponent('DashboardUmatPage'),
            },
            //role
            {
              path: '/role',
              name: 'Role',
              meta: {title : 'Role'},
              component: importViewComponent('RolePage'),
            },
            //petugas
            {
              path: '/petugas',
              name: 'Petugas',
              meta: {title : 'Petugas'},
              component: importViewComponent('PetugasPage'),
            },
            //tim pelaksana
            {
              path: '/timpelaksana',
              name: 'TimPelaksana',
              meta: {title : 'Tim Pelaksana'},
              component: importViewComponent('TimPelaksanaPage'),
            },
            //jadwal misa
            {
              path: '/jadwalmisa',
              name: 'JadwalMisa',
              meta: {title : 'Jadwal Misa'},
              component: importViewComponent('JadwalMisaPage'),
            },
            //booking bertugas
            {
              path: '/bookingbertugas',
              name: 'BookingBertugas',
              meta: {title : 'Pemesanan Jadwal'},
              component: importViewComponent('PemesananJadwalPage'),
            },
            //detail misa
            {
              path: '/detailmisa',
              name: 'DetailMisa',
              meta: {title : 'Detail Misa'},
              component: importViewComponent('DetailMisaPage'),
            },
            //detail jabatan
            {
              path: '/detailjabatan',
              name: 'DetailJabatan',
              meta: {title : 'Detail Jabatan'},
              component: importViewComponent('DetailJabatanPage'),
            },
            //perubahan jadwal
            {
              path: '/perubahanjadwal',
              name: 'PerubahanJadwal',
              meta: {title : 'Perubahan Jadwal'},
              component: importViewComponent('PerubahanJadwalPage'),
            },
            //kehadiran petugas
            {
              path: '/kehadiranpetugas',
              name: 'KehadiranPetugas',
              meta: {title : 'Kehadiran Petugas'},
              component: importViewComponent('KehadiranPetugasPage'),
            },
            //pengumuman
            {
              path: '/pengumuman',
              name: 'Pengumuman',
              meta: {title : 'Pengumuman'},
              component: importViewComponent('PengumumanPage'),
            },
            //doaumat
            {
              path: '/doaumat',
              name: 'DoaUmat',
              meta: {title : 'Doa Umat'},
              component: importViewComponent('DoaUmatPage'),
            },
            //umat
            {
              path: '/umat',
              name: 'Umat',
              meta: {title : 'Umat'},
              component: importViewComponent('UmatPage'),
            },
            //lingkungan
            {
              path: '/lingkungan',
              name: 'Lingkungan',
              meta: {title : 'Lingkungan'},
              component: importViewComponent('LingkunganPage'),
            },
            //romo
            {
              path: '/romo',
              name: 'Romo',
              meta: {title : 'Romo'},
              component: importViewComponent('RomoPage'),
            },
            //janji temu romo
            {
              path: '/janjitemuromo',
              name: 'JanjiTemuRomo',
              meta: {title : 'JanjiTemuRomo'},
              component: importViewComponent('JanjiTemuRomoPage'),
            },
            {
              path: '/pelayanansakramen',
              name: 'PelayananSakramen',
              meta: {title : 'PelayananSakramen'},
              component: importViewComponent('PelayananSakramenPage'),
            },
            //pelayanan umum
            {
              path: '/pelayananumum',
              name: 'PelayananUmum',
              meta: {title : 'PelayananUmum'},
              component: importViewComponent('PelayananUmumPage'),
            },
            //history umat
            {
              path: '/historyumat',
              name: 'HistoryUmat',
              meta: {title : 'HistoryUmat'},
              component: importViewComponent('HistoryUmatPage'),
            },
            //history pelayanan umum umat
            {
              path: '/historyumat-umum',
              name: 'HistoryUmatUmum',
              meta: {title : 'HistoryUmatUmum'},
              component: importViewComponent('HistoryUmatUmumPage'),
            },
            //history doa umat
            {
              path: '/historyumat-doaumat',
              name: 'HistoryDoaUmat',
              meta: {title : 'HistoryDoaUmat'},
              component: importViewComponent('HistoryDoaUmatPage'),
            },
            //history janji temu romo
            {
              path: '/historyumat-janjitemuromo',
              name: 'HistoryJanjiTemuRomo',
              meta: {title : 'HistoryJanjiTemuRomo'},
              component: importViewComponent('HistoryJanjiTemuRomoPage'),
            },
            //update doa umat
            {
              path: '/historyumat-updatedoaumat/:id',
              name: 'UpdateDoaUmat',
              meta: {title : 'UpdateDoaUmat'},
              component: importViewComponent('UpdateDoaUmatPage'),
            },
            //update history sakramen baptis anak umat
            {
              path: '/historyumat-sakramenbaptisanak/:id',
              name: 'UpdateSakramenBaptisAnak',
              meta: {title : 'UpdateSakramenBaptisAnak'},
              component: importViewComponent('UpdateSakramenBaptisAnakPage'),
            },
            //update history sakramen baptis dewasa umat
            {
              path: '/historyumat-sakramenbaptisdewasa/:id',
              name: 'UpdateSakramenBaptisDewasa',
              meta: {title : 'UpdateSakramenBaptisDewasa'},
              component: importViewComponent('UpdateSakramenBaptisDewasaPage'),
            },
            //update history sakramen komuni pertama umat
            {
              path: '/historyumat-sakramenkomunipertama/:id',
              name: 'UpdateSakramenKomuniPertama',
              meta: {title : 'UpdateSakramenKomuniPertama'},
              component: importViewComponent('UpdateSakramenKomuniPertamaPage'),
            },
            //update history sakramen krisma
            {
              path: '/historyumat-sakramenkrisma/:id',
              name: 'UpdateSakramenKrisma',
              meta: {title : 'UpdateSakramenKrisma'},
              component: importViewComponent('UpdateSakramenKrismaPage'),
            },
            //update history sakramen perkawinan
            {
              path: '/historyumat-sakramenperkawinan/:id',
              name: 'UpdateSakramenPerkawinan',
              meta: {title : 'UpdateSakramenPerkawinan'},
              component: importViewComponent('UpdateSakramenPerkawinanPage'),
            },
            //update surket kematian
            {
              path: '/historyumat-umum-surketkematian/:id',
              name: 'UpdateSurketKematian',
              meta: {title : 'UpdateSurketKematian'},
              component: importViewComponent('UpdateSurketKematianPage'),
            },
            //update surket anggota lingkungan
            {
              path: '/historyumat-umum-surketlingkungan/:id',
              name: 'UpdateSurketAnggotaLingkungan',
              meta: {title : 'UpdateSurketAnggotaLingkungan'},
              component: importViewComponent('UpdateSurketAnggotaLingkunganPage'),
            },
            //update surket pelayanan ekaristi
            {
              path: '/historyumat-umum-surketpelayanekaristi/:id',
              name: 'UpdateSurketPelayanEkaristi',
              meta: {title : 'UpdateSurketPelayanEkaristi'},
              component: importViewComponent('UpdateSurketPelayanEkaristiPage'),
            },
            //update surket pindah domisili
            {
              path: '/historyumat-umum-surketpindahdomisili/:id',
              name: 'ListJadwalMisaPetugas',
              meta: {title : 'UpdateSurketPindahDomisili'},
              component: importViewComponent('UpdateSurketPindahDomisiliPage'),
            },
            //list jadwal misa petugas
            {
              path: '/jadwalmisapetugas',
              name: 'ListJadwalMisaPetugas',
              meta: {title : 'ListJadwalMisaPetugas'},
              component: importViewComponent('ListJadwalMisaPetugasPage'),
            },
            //permintaan tukar jadwal petugas
            {
              path: '/permintaantukarjadwal',
              name: 'PermintaanTukarJadwalPetugas',
              meta: {title : 'PermintaanTukarJadwalPetugas'},
              component: importViewComponent('PermintaanTukarJadwalPetugasPage'),
            },
            //history pemesanan jadwal bertugas
            {
              path: '/historypemesananjadwalbertugas',
              name: 'HistoryPemesananJadwalBertugas',
              meta: {title : 'HistoryPemesananJadwalBertugas'},
              component: importViewComponent('HistoryPemesananJadwalBertugasPage'),
            },
            //lokasi misa
            {
              path: '/lokasimisa',
              name: 'LokasiMisa',
              meta: {title : 'LokasiMisa'},
              component: importViewComponent('LokasiMisaPage'),
            },
            //kategori misa
            {
              path: '/kategorimisa',
              name: 'KategoriMisa',
              meta: {title : 'KategoriMisa'},
              component: importViewComponent('KategoriMisaPage'),
            },
            //tim pelaksana romo only
            {
              path: '/timpel',
              name: 'TimPelaksanaRomo',
              meta: {title : 'TimPelaksanaRomo'},
              component: importViewComponent('TimPelaksanaRomoPage'),
            },
            //jadwal misa romo only
            {
              path: '/agendamisa',
              name: 'JadwalMisaRomo',
              meta: {title : 'JadwalMisaRomo'},
              component: importViewComponent('JadwalMisaRomoPage'),
            },
            //presensi kehadiran petugas romo only
            {
              path: '/presensikehadiran',
              name: 'PresensiKehadiranPetugasRomo',
              meta: {title : 'PresensiKehadiranPetugasRomo'},
              component: importViewComponent('PresensiKehadiranPetugasRomoPage'),
            },
            //pemesanan jadwal bertugas romo only
            {
              path: '/pemesananbertugas',
              name: 'PemesananBertugasRomo',
              meta: {title : 'PemesananBertugasRomo'},
              component: importViewComponent('PemesananBertugasRomoPage'),
            },
            //presensi kehadiran petugas only
            {
              path: '/catatkehadiran',
              name: 'PresensiKehadiranPetugasOnly',
              meta: {title : 'PresensiKehadiranPetugasOnly'},
              component: importViewComponent('PresensiKehadiranPetugasOnlyPage'),
            },
            //detail misa romo only
            {
              path: '/kuotatimpel',
              name: 'DetailMisaRomoOnly',
              meta: {title : 'DetailMisaRomoOnly'},
              component: importViewComponent('DetailMisaRomoOnlyPage'),
            },
            //crud booking bertugas
            {
              path: '/bookschedule',
              name: 'BookingBertugas',
              meta: {title : 'BookingBertugas'},
              component: importViewComponent('BookingBertugasPage'),
            },
          ]
    },
    {
      path: '*',
      redirect: '/'
    },
  ]
})

router.beforeEach((to, from, next) => {
  //all data
  if(to.name == "DashboardPage" && localStorage.getItem("id_petugas") == null ||
    to.name == "EditProfile" && localStorage.getItem("id_petugas") == null ||
    to.name == "Role" && localStorage.getItem("id_petugas") == null ||
    to.name == "Petugas" && localStorage.getItem("id_petugas") == null ||
    to.name == "TimPelaksana" && localStorage.getItem("id_petugas") == null ||
    to.name == "JadwalMisa" && localStorage.getItem("id_petugas") == null ||
    to.name == "BookingBertugas" && localStorage.getItem("id_petugas") == null ||
    to.name == "DetailMisa" && localStorage.getItem("id_petugas") == null ||
    to.name == "DetailJabatan" && localStorage.getItem("id_petugas") == null ||
    to.name == "PerubahanJadwal" && localStorage.getItem("id_petugas") == null ||
    to.name == "KehadiranPetugas" && localStorage.getItem("id_petugas") == null ||
    to.name == "DoaUmat" && localStorage.getItem("id_petugas") == null ||
    to.name == "Umat" && localStorage.getItem("id_petugas") == null ||
    to.name == "Lingkungan" && localStorage.getItem("id_petugas") == null ||
    to.name == "Romo" && localStorage.getItem("id_petugas") == null ||
    to.name == "JanjiTemuRomo" && localStorage.getItem("id_petugas") == null ||
    to.name == "PelayananSakramen" && localStorage.getItem("id_petugas") == null ||
    to.name == "PelayananUmum" && localStorage.getItem("id_petugas") == null ){
        next('home')
        document.to.meta.title = "HomePage"
    }

    document.title = to.meta.title;
    next();
});

export default router
